import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatMoney } from '../../lib/fmt';

export default class DStockReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    date1: PropTypes.string.isRequired,
    date2: PropTypes.string.isRequired,
  };

  render() {
    return (
      <div className="DStockReport">
        {this.renderHeader()}
        {this.renderItems()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { date1 } = this.props;
    const d = dateFns.format(date1, 'DD.MM.YYYY');
    return (
      <div>
        <h1 className="text-center">Ведомость материальных остатков</h1>
        <h2 className="text-center mb-3">({d})</h2>
      </div>
    );
  }

  renderItems() {
    const { items, qty, amount } = this.processReport();
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th></th>
            <th>Наименование</th>
            <th>Код</th>
            <th>Ед. изм.</th>
            <th className="text-nowrap">Кол-во</th>
            <th>Цена входная</th>
            <th>Сумма входная</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => this.renderItem(item, index))}
          <tr className="font-weight-bold">
            <td colSpan="4">Итого:</td>
            <td>{qty}</td>
            <td></td>
            <td className="text-nowrap">{formatMoney(amount, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderItem(item, index) {
    return (
      <tr key={index}>
        <td>{index + 1}</td>
        <td>{item.product_name}</td>
        <td>{item.product_sku_producer}</td>
        <td>{item.product_unit}</td>
        <td>{item.qty}</td>
        <td className="text-nowrap">{formatMoney(item.price, true, true)}</td>
        <td>{formatMoney(item.amount, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processReport() {
    const { report } = this.props;
    const { items } = report;
    const result = {
      items: report.items,
      qty: 0,
      amount: 0,
    };
    for (const item of items) {
      result.qty += item.qty;
      result.amount += item.amount;
    }
    return result;
  }
}
