import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../../lib/utils';
import { getBaseWorkerOptions } from '../../services/worker.service';
import Button from '../Button';
import FilterButton from '../FilterButton';
import FormGroup from '../FormGroup';
import MDirPicker from './MDirPicker';
import MProductPicker from './MProductPicker';

export default class MReport extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    view: PropTypes.func.isRequired,
    // --
    withoutDates: PropTypes.bool,
    withWorkerOption: PropTypes.bool,
    withProviderOption: PropTypes.bool,
    withSingleDate: PropTypes.bool,
    withGroupByOrder: PropTypes.bool,
    withProductFilter: PropTypes.bool,
    subtypes: PropTypes.array,
  };

  constructor(props) {
    super(props);
    const { app, subtypes } = props;
    const today = dateFns.format(new Date(), 'YYYY-MM-DD');
    this.state = {
      isLoading: false,
      workers: [],
      providers: [],
      report: undefined,
      form: {
        d1: app.loadFromCache('reports_date1') || today,
        d2: app.loadFromCache('reports_date2') || today,
        worker: '',
        provider: '',
        groupByOrder: 'off',
        subtypeReport: subtypes ? subtypes[0].value : '',
        productGroupId: '',
        productIds: [],
      },
    };
  }

  render() {
    return (
      <div className="modal-dialog modal-md">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    try {
      const { items: workers } = await app.getApi().get('/workers');
      const { items: contractors } = await app.getApi().get('/contractors');
      const providers = contractors.filter((c) => c.is_provider);
      this.setState({ workers, providers });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onFormChange(form) {
    const today = dateFns.format(new Date(), 'YYYY-MM-DD');
    form.d1 = form.d1 || today;
    form.d2 = form.d2 || today;
    this.setState({ form });
  }

  async onGetReport() {
    const { app, type, withSingleDate, withoutDates, subtypes, withProductFilter } = this.props;
    const { form } = this.state;
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      let url = `/reports/${type}`;
      let queryData = {};
      if (subtypes) {
        const subtypeReport = form.subtypeReport;
        url += `/${subtypeReport}`;
      }
      if (!withoutDates) {
        const date1 = dateFns.parse(form.d1);
        const date2 = dateFns.addDays(dateFns.parse(form.d2), 1);
        const t1 = this.getUnixTimestamp(date1);
        const t2 = this.getUnixTimestamp(date2);
        url += withSingleDate ? `/${t1}` : `/${t1}-${t2}`;
      }
      if (withProductFilter) {
        if (form.productGroupId) {
          queryData.product_group_id = form.productGroupId;
        }
        if (form.productIds.length > 0) {
          queryData.product_ids = form.productIds.join(',');
        }
      }
      const report = await api.get(url, queryData);
      this.setState({ isLoading: false, report });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
      return;
    }
    if (!withoutDates) {
      app.saveToCache('reports_date1', form.d1);
      app.saveToCache('reports_date2', form.d2);
    }
  }

  onShowReport() {
    const { app, view } = this.props;
    const { form, report } = this.state;
    const workerId = form.worker ? Number(form.worker) : undefined;
    const providerId = form.provider ? Number(form.provider) : undefined;
    const isGroupByOrder = form.groupByOrder ? form.groupByOrder === 'on' : undefined;
    const subtypeReport = form.subtypeReport || undefined;
    app.showDocument('Отчёт', view, {
      report,
      date1: form.d1,
      date2: form.d2,
      workerId,
      providerId,
      isGroupByOrder,
      subtypeReport,
    });
    this.setState({ report: undefined });
  }

  async onSelectProducts() {
    const { app } = this.props;
    let form = { ...this.state.form };
    const selectedProducts = await app.showModal(MProductPicker, {
      priceType: 1,
      shouldIgnorePriceAndQty: true,
      selectedIds: form.productIds,
    });
    if (!selectedProducts) {
      return;
    }
    form.productIds = selectedProducts.map((item) => item.product.id);
    this.setState({ form });
  }

  onResetProducts() {
    let form = { ...this.state.form };
    form.productIds = [];
    this.setState({ form });
  }

  async onSelectProductDir() {
    const { app } = this.props;
    let form = { ...this.state.form };
    const dirId = await app.showModal(MDirPicker, {
      type: 'products',
      ...(form.productGroupId && { dirId: form.productGroupId }),
    });
    if (!dirId) {
      return;
    }
    form.productGroupId = dirId;
    this.setState({ form });
  }

  onResetProductDir() {
    let form = { ...this.state.form };
    form.productGroupId = '';
    this.setState({ form });
  }

  // render helpers

  renderHeader() {
    const { close, title } = this.props;
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderBody() {
    const {
      withoutDates,
      withWorkerOption,
      withProviderOption,
      withGroupByOrder,
      withSingleDate,
      withProductFilter,
      subtypes,
    } = this.props;
    const { form, report } = this.state;
    const isReportReady = Boolean(report);
    const maxDate = dateFns.format(new Date(), 'YYYY-MM-DD');
    return (
      <form className="modal-body">
        <div className={cn('row', withoutDates && 'd-none')}>
          <FormGroup
            className="col"
            type="date"
            name="d1"
            label={withSingleDate ? 'Дата *' : 'Начало периода *'}
            form={form}
            minDate={this.getAllowedStartDate()}
            maxDate={maxDate}
            disabled={isReportReady}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className={cn('row', (withoutDates || withSingleDate) && 'd-none')}>
          <FormGroup
            className="col"
            type="date"
            name="d2"
            label="Окончание периода *"
            form={form}
            minDate={this.getAllowedStartDate()}
            maxDate={maxDate}
            disabled={isReportReady}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className={cn('row', !withWorkerOption && 'd-none')}>
          <FormGroup
            className="col"
            type="select"
            options={this.getWorkerOptions()}
            name="worker"
            label="Исполнитель"
            form={form}
            disabled={isReportReady}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className={cn('row', !withProviderOption && 'd-none')}>
          <FormGroup
            className="col"
            type="select"
            options={this.getProviderOptions()}
            name="provider"
            label="Поставщик"
            form={form}
            disabled={isReportReady}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className={cn('row', !withGroupByOrder && 'd-none')}>
          <FormGroup
            className="col"
            type="checkbox"
            name="groupByOrder"
            checkLabel="Группировать по заказам"
            form={form}
            disabled={isReportReady}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        {subtypes && (
          <div className="row">
            <FormGroup
              className="col"
              type="select"
              options={subtypes}
              name="subtypeReport"
              label="Тип отчёта"
              form={form}
              disabled={isReportReady}
              onChange={(newForm) => this.onFormChange(newForm)}
            />
          </div>
        )}
        {withProductFilter && (
          <div className="row">
            <div className="col form-group">
              <label>Фильтр по запчастям</label>
              <div className="row">
                <FilterButton
                  className="col-6"
                  value={form.productGroupId}
                  label="Группа"
                  isDisabled={isReportReady}
                  onClickFilter={() => this.onSelectProductDir()}
                  onResetFilter={() => this.onResetProductDir()}
                />
                <FilterButton
                  className="col-6"
                  value={form.productIds}
                  label="Позиции"
                  isDisabled={isReportReady}
                  onClickFilter={() => this.onSelectProducts()}
                  onResetFilter={() => this.onResetProducts()}
                />
              </div>
            </div>
          </div>
        )}
      </form>
    );
  }

  renderFooter() {
    const { isLoading, report } = this.state;
    return (
      <div className="modal-footer">
        {!report && (
          <Button
            className="w-100"
            type="primary"
            text="Получить отчёт"
            disabled={isLoading}
            onClick={() => this.onGetReport()}
          />
        )}
        {report && <Button className="w-100" type="success" text="Открыть отчёт" onClick={() => this.onShowReport()} />}
      </div>
    );
  }

  // other helpers

  getAllowedStartDate() {
    const { app, type } = this.props;
    if (app.hasRole('boss')) {
      return undefined;
    }
    const now = Date.now();
    const min = ['profit', 'order'].includes(type) ? dateFns.addWeeks(now, -1) : dateFns.addMonths(now, -1);
    return dateFns.format(min, 'YYYY-MM-DD');
  }

  getWorkerOptions() {
    const { workers } = this.state;
    const first = { value: '', title: 'Все сотрудники' };
    const options = getBaseWorkerOptions(workers);
    return [first, ...options];
  }

  getProviderOptions() {
    const { providers } = this.state;
    const first = { value: '', title: 'Все поставщики' };
    const options = providers.map((p) => ({ value: p.id, title: p.name }));
    return [first, ...options];
  }

  getUnixTimestamp(date) {
    return Math.round(date.valueOf() / 1000);
  }
}
