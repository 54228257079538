import PropTypes from 'prop-types';
import React from 'react';

import { formatInt, formatMoney, formatDate } from '../../lib/fmt';
import { parseMoney, parseNumber } from '../../lib/parser';
import { asyncAlert, coalesce } from '../../lib/utils';
import Button from '../Button';
import FormGroup from '../FormGroup';
import List from '../List';
import ModalNav from '../ModalNav';
import MProducerEdit from './MProducerEdit';
import MOrderEdit from './MOrderEdit';
import MStockMovementEdit from './MStockMovementEdit';

import {
  formHasError,
  formNormalizeMoney,
  formResetErrors,
  formTrimAll,
  formValidateMoney,
  formValidateRegexp,
  formValidateRequired,
} from '../../lib/form';

const NAV_GENERAL = 'general';
const NAV_STOCK = 'stock';
const NAV_PHOTO = 'photo';
const NAV_ANALOGS = 'analogs';
const NAV_HISTORY = 'history';

const NAVS = [
  [NAV_GENERAL, 'Основное'],
  [NAV_STOCK, 'Склад'],
  [NAV_PHOTO, 'Фото'],
  [NAV_ANALOGS, 'Аналоги'],
  [NAV_HISTORY, 'История'],
];

const OPTIONS_UNIT = [
  { value: 'шт.', title: 'Штука (шт.)' },
  { value: 'кг.', title: 'Килограмм (кг)' },
  { value: 'г', title: 'Грамм (г)' },
  { value: 'л', title: 'Литр (л)' },
  { value: 'мл', title: 'Миллилитр (мл)' },
  { value: 'м', title: 'Метр (м)' },
];

const OPTIONS_PRICING_MODEL = [
  { value: 'storage', title: 'Хранение' },
  { value: 'calculation', title: 'Расчёт' },
];

export default class MProductEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    // --
    product: PropTypes.object,
    dirId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isUploading: false,
      nav: NAV_GENERAL,
      form: this.initForm(),
      newImage: null,
      delImage: false,
      dirs: props.app.loadFromCache('products/dirs') || [],
      producers: [],
      products: [],
      history: [],
      historyFilter: 'all',
    };
  }

  render() {
    return (
      <div className="MProductEdit modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app, product } = this.props;
    const api = app.getApi();
    try {
      const { items: dirs } = await api.get('/products/dirs');
      const { items: producers } = await api.get('/producers');
      const { items: products } = await api.get('/products');
      let history = [];
      if (product) {
        const resHistory = await api.get(`/products/${product.id}/history`);
        history = resHistory.items;
      }
      this.setState({ dirs, producers, products, history });
    } catch (err) {
      app.onError(err);
    }
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  async onFormChange(form) {
    const { app } = this.props;
    let producers = this.state.producers;
    let producer = form.producer;
    if (producer === 'new') {
      const newProducer = await app.showModal(MProducerEdit);
      if (newProducer) {
        const { items } = await app.getApi().get('/producers');
        producers = items;
        producer = String(newProducer.id);
      } else {
        producer = this.state.form.producer;
      }
    }
    const pricingModelChanged = this.state.form.pricing_model !== form.pricing_model;
    const newForm = {
      ...(pricingModelChanged ? formResetErrors(form) : form),
      percent: pricingModelChanged ? '' : form.percent,
      price_2: pricingModelChanged ? '' : form.price_2,
      producer,
    };
    this.setState({
      form: {
        ...this.updatePrice(newForm),
        sku_producer: this.fixSku(form.sku_producer),
        sku_original: this.fixSku(form.sku_original),
        name: this.fixTitle(form.name),
      },
      producers,
    });
  }

  async onFileChange(e) {
    const { app } = this.props;
    const api = app.getApi();
    this.setState({ isUploading: true });
    try {
      const { id, hash, name } = await api.upload(e.target.files[0]);
      this.setState({
        isUploading: false,
        newImage: { id, hash, name },
        delImage: false,
      });
    } catch (err) {
      this.setState({ isUploading: false });
      app.onError(err);
    }
  }

  onDeleteImage() {
    this.setState({
      newImage: null,
      delImage: true,
    });
  }

  onHistoryFilterChange(e) {
    this.setState({ historyFilter: e.target.value });
  }

  async onSave() {
    const { app, close, product } = this.props;
    const { newImage, delImage } = this.state;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizeMoney(form, 'price_1');
    form = formNormalizeMoney(form, 'price_2');
    form = formValidateRequired(form, 'dir');
    form = formValidateRequired(form, 'producer');
    form = formValidateRequired(form, 'name');
    form = formValidateRequired(form, 'sku_producer');
    form = formValidateRequired(form, 'sku_original');
    form = formValidateRegexp(form, 'sku_producer', /^[0-9A-Z-]{4,20}$/); // ?
    form = formValidateRegexp(form, 'sku_original', /^[0-9A-Z-]{4,20}$/); // ?
    form = formValidateMoney(form, 'price_1');
    form = formValidateMoney(form, 'price_2');
    form = formValidateRegexp(form, 'percent', /^\d+$/);
    form = formValidateRegexp(form, 'order_q', /^\d+$/);
    form = formValidateRegexp(form, 'stock_min', /^\d+$/);
    form = formValidateRegexp(form, 'stock_max', /^\d+$/);
    if (formHasError(form)) {
      this.setState({ nav: NAV_GENERAL, form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form);
      let productId;
      if (product) {
        productId = product.id;
        await api.put(`/products/${productId}`, pd);
        if (delImage) {
          await api.delete(`/products/${productId}/image`, pd);
        }
      } else {
        productId = (await api.post('/products', pd)).id;
      }
      if (newImage) {
        await api.put(`/products/${productId}/image`, {
          id: newImage.id,
          hash: newImage.hash,
        });
      }
      const res = await app.getApi().get(`/products/${productId}`);
      close(res.product);
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onDelete() {
    const { app, close, product } = this.props;
    if (!window.confirm('Удалить запчасть?')) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().delete(`/products/${product.id}`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onCopy() {
    const { app, close, product } = this.props;
    this.setState({ isLoading: true });
    try {
      const name = product.name;
      const copySuffix = ' (копия)';
      const copyName = name.endsWith(copySuffix) ? name : name + copySuffix;
      const res1 = await app.getApi().post(`/products/${product.id}/copy`, {
        name: copyName,
      });
      const res2 = await app.getApi().get(`/products/${res1.id}`);
      await app.showModal(MProductEdit, {
        product: res2.product,
      });
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onHistoryItemSelect(item) {
    const { app } = this.props;
    const api = app.getApi();
    if (item.ord_id) {
      const { order } = await api.get(`/orders/${item.ord_id}`);
      if (!order) {
        return;
      }
      await app.showModal(MOrderEdit, { order });
    } else if (item.stock_movement_id) {
      const { stockMovement } = await api.get(`/stock/movements/${item.stock_movement_id}`);
      if (!stockMovement) {
        return;
      }
      await app.showModal(MStockMovementEdit, { stockMovement });
    }
  }

  // render helpers

  renderHeader() {
    const { close, product } = this.props;
    const title = product ? product.name : 'Новая запчасть';
    return (
      <div className="modal-header">
        <h5 className="modal-title">{title}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { product } = this.props;
    const { nav } = this.state;
    const navs = [...NAVS];
    if (!product) {
      navs.pop();
    }
    return <ModalNav items={navs} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body overflow-scroll">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_GENERAL:
        return this.renderGeneral();
      case NAV_STOCK:
        return this.renderStock();
      case NAV_PHOTO:
        return this.renderPhoto();
      case NAV_ANALOGS:
        return this.renderAnalogs();
      case NAV_HISTORY:
        return this.renderHistory();
      default:
        return null;
    }
  }

  renderGeneral() {
    const { form, dirs } = this.state;
    const isStorage = form.pricing_model === 'storage';
    return (
      <form>
        <div className="row">
          <FormGroup
            className="col"
            type="dir"
            dirs={dirs}
            name="dir"
            label="Папка *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col-8"
            type="text"
            name="name"
            label="Название *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col-4"
            type="select"
            options={this.getProducerOptions()}
            name="producer"
            label="Производитель *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="sku_original"
            label="Код оригинальный *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="sku_producer"
            label="Код производителя *"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_UNIT}
            name="unit"
            label="Единица измерения"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={OPTIONS_PRICING_MODEL}
            name="pricing_model"
            label="Ценообразование"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="percent"
            label="Процент"
            form={form}
            disabled={isStorage}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="price_1"
            label="Входная цена"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="price_2"
            label="Розничная цена"
            form={form}
            disabled={!isStorage}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderStock() {
    const { form } = this.state;
    return (
      <form>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="stock_place"
            label="Место на складе"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="order_q"
            label="Оптимальное количество для заказа"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="text"
            name="stock_min"
            label="Минимальный остаток"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="stock_max"
            label="Максимальный остаток"
            form={form}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </form>
    );
  }

  renderPhoto() {
    return this.hasImage() ? this.renderPhotoImage() : this.renderPhotoInput();
  }

  renderPhotoInput() {
    const { isUploading } = this.state;
    return (
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="productImageInput"
          accept="image/*"
          onChange={(e) => this.onFileChange(e)}
        />
        <label className="custom-file-label" htmlFor="productImageInput">
          {isUploading ? 'Загрузка...' : 'Загрузить фото'}
        </label>
      </div>
    );
  }

  renderPhotoImage() {
    const { app, product } = this.props;
    const { newImage } = this.state;
    const url = app.getImageUrl(newImage ? newImage.name : product.image_name);
    return <img className="MProductEdit_image border" src={url} alt="" />;
  }

  renderAnalogs() {
    const analogs = this.getAnalogs();
    if (analogs.length === 0) {
      return 'По кодам не найдено ни одного аналога';
    }
    const columns = [
      {
        name: 'Код производителя',
        value: (item) => item.sku_producer,
      },
      {
        name: 'Производитель',
        value: (item) => `${item.producer_name}, ${item.producer_country}`,
      },
      {
        name: 'Цена',
        value: (item) => formatMoney(item.price_2, true, true),
      },
    ];
    return <List columns={columns} items={analogs} />;
  }

  renderHistory() {
    const { history, historyFilter } = this.state;
    if (history.length === 0) {
      return 'Не найдено не поступлений, ни продаж по данной запчасти';
    }
    const columns = [
      {
        name: '',
        value: (item) => {
          const icon = item.ord_id ? 'left' : 'right';
          const color = item.ord_id ? 'success' : 'primary';
          return <i className={`fas fa-arrow-circle-${icon} text-${color}`} />;
        },
        headClassName: 'w-40px',
      },
      {
        name: 'Тип',
        value: (item) => (item.ord_id ? 'Продажа' : 'Поступление'),
      },
      {
        name: '#',
        value: (item) => item.ord_id || item.stock_movement_id,
        headClassName: 'w-100px',
      },
      {
        name: 'Дата',
        value: (item) => formatDate(item.applied_at),
        headClassName: 'w-120px',
      },
      {
        name: 'Цена',
        value: (item) => formatMoney(item.price, true, true),
        headClassName: 'w-120px',
      },
      {
        name: 'Кол-во',
        value: (item) => item.qty,
        headClassName: 'w-120px',
      },
      {
        name: 'Стоимость',
        value: (item) => formatMoney(item.price * item.qty, true, true),
      },
    ];
    let items = history;
    if (historyFilter !== 'all') {
      const field = historyFilter === 'in' ? 'stock_movement_id' : 'ord_id';
      items = items.filter((x) => x[field]);
    }
    return (
      <div>
        <select className="form-control mb-3" value={historyFilter} onChange={(e) => this.onHistoryFilterChange(e)}>
          <option value="all">Все</option>
          <option value="in">Поступления</option>
          <option value="out">Продажи</option>
        </select>
        <List columns={columns} items={items} onItemSelect={async (item) => this.onHistoryItemSelect(item)} />
      </div>
    );
  }

  renderFooter() {
    const { close, product } = this.props;
    const { isLoading, nav } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto">
          {product && nav === NAV_GENERAL && (
            <Button
              className="mr-2 d-none"
              type="info"
              text="Копировать"
              disabled={isLoading}
              onClick={() => this.onCopy()}
            />
          )}
          {product && nav === NAV_GENERAL && (
            <Button
              className="mr-2"
              type="danger"
              text="Удалить"
              icon="trash"
              disabled={isLoading || !this.canDelete()}
              onClick={() => this.onDelete()}
            />
          )}
          {product && nav === NAV_PHOTO && this.hasImage() && (
            <Button
              className="mr-2"
              type="warning"
              text="Удалить фото"
              icon="trash"
              disabled={!this.canEdit()}
              onClick={() => this.onDeleteImage()}
            />
          )}
        </div>
        <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => close()} />
        <Button type="success" text="Сохранить" disabled={isLoading || !this.canEdit()} onClick={() => this.onSave()} />
      </div>
    );
  }

  // other helpers

  canEdit() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  initForm() {
    const { app, product, dirId } = this.props;
    const config = app.getConfig();
    if (!product) {
      return {
        dir: dirId === config.dirRootId ? '' : formatInt(dirId),
        producer: '',
        unit: 'шт.',
        name: '',
        sku_producer: '',
        sku_original: '',
        pricing_model: 'calculation',
        percent: '30',
        price_1: '',
        price_2: '',
        stock_place: '',
        order_q: '',
        stock_min: '',
        stock_max: '',
      };
    }
    const form = {
      dir: String(product.dir_id),
      producer: String(product.producer_id),
      unit: product.unit,
      name: product.name,
      sku_producer: product.sku_producer,
      sku_original: product.sku_original,
      pricing_model: product.pricing_model,
      percent: formatInt(product.percent),
      price_1: formatMoney(product.price_1, true),
      price_2: formatMoney(product.price_2, true),
      stock_place: product.stock_place,
      order_q: formatInt(product.order_q),
      stock_min: formatInt(product.stock_min),
      stock_max: formatInt(product.stock_max),
    };
    return this.updatePrice(form);
  }

  getPostData(form) {
    return {
      dir_id: Number(form.dir),
      producer_id: Number(form.producer),
      unit: form.unit,
      name: form.name,
      sku_producer: form.sku_producer,
      sku_original: form.sku_original,
      pricing_model: form.pricing_model,
      percent: coalesce(parseNumber(form.percent), null),
      price_1: parseMoney(form.price_1) || null,
      price_2: parseMoney(form.price_2) || null,
      stock_place: form.stock_place,
      order_q: coalesce(parseNumber(form.order_q), null),
      stock_min: coalesce(parseNumber(form.stock_min), null),
      stock_max: coalesce(parseNumber(form.stock_max), null),
    };
  }

  getProducerOptions() {
    const { product } = this.props;
    const { producers } = this.state;
    const first = { value: '', title: 'Выбрать', disabled: true };
    const last = { value: 'new', title: 'Добавить' };
    const options = producers.map((p) => ({
      value: p.id,
      title: `${p.name}, ${p.country}`,
    }));
    if (product && options.length === 0) {
      options.push({
        value: product.producer_id,
        title: `${product.producer_name}, ${product.producer_country}`,
      });
    }
    return [first, ...options, last];
  }

  updatePrice(form) {
    if (form.pricing_model === 'storage') {
      return form;
    }
    const price1 = parseMoney(form.price_1);
    const percent = parseNumber(form.percent);
    const price2 = price1 === undefined || percent === undefined ? null : Math.round(price1 + (price1 * percent) / 100);
    return {
      ...form,
      price_2: formatMoney(price2, true),
    };
  }

  getAnalogs() {
    const { product } = this.props;
    const { products } = this.state;
    const { form } = this.state;
    const codes = [form.sku_producer, form.sku_original];
    return products.filter((p) => {
      if (product && product.id === p.id) {
        return false;
      }
      if (codes.includes(p.sku_producer)) {
        return true;
      }
      if (codes.includes(p.sku_original)) {
        return true;
      }
      return false;
    });
  }

  fixSku(value) {
    return value.replace(/\s/g, '').toUpperCase();
  }

  fixTitle(value) {
    return value ? value.substr(0, 1).toUpperCase() + value.substr(1) : value;
  }

  hasImage() {
    const { product } = this.props;
    const { newImage, delImage } = this.state;
    return newImage || (product && product.image_id && !delImage);
  }
}
