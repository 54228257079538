import PropTypes from 'prop-types';
import React from 'react';

import { cn } from '../lib/utils';
import { formatDate } from '../lib/fmt';
import MClaimEdit from './modals/MClaimEdit';
import MOrderEdit from './modals/MOrderEdit';
import VoidLink from './VoidLink';

export default class HistoryGrid extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    orders: PropTypes.array.isRequired,
    claims: PropTypes.array.isRequired,
    workers: PropTypes.array.isRequired,
    // --
    itemId: PropTypes.number,
  };

  constructor(props) {
    super(props);
    this.state = {
      itemId: props.itemId,
      itemTopRowIndex: 0,
      itemRowCount: 0,
    };
  }

  render() {
    const { orders, claims } = this.props;
    return (
      <div className="ScrollGrid">
        <table className="Grid table table-sm table-bordered">
          <thead>
            <tr>
              <th className="Grid_cellMergeRight w-40px"></th>
              <th colSpan="2">Работы</th>
              <th className="Grid_cellMergeRight w-40px"></th>
              <th colSpan="2">Запчасти</th>
            </tr>
          </thead>
          <tbody>
            {orders.length > 0 && this.renderItems(orders, 'order')}
            {claims.length > 0 && this.renderItems(claims, 'claim')}
          </tbody>
        </table>
      </div>
    );
  }

  async componentDidMount() {}

  // event handlers

  onChange(items) {
    if (this.props.onChange) {
      this.props.onChange(items);
    }
  }

  async onTitleClick(id, type) {
    return type === 'order' ? this.onOrderClick(id) : this.onClaimClick(id);
  }

  async onOrderClick(id, nav) {
    const { app } = this.props;
    const api = app.getApi();
    let item;
    try {
      item = await api.get(`/orders/${id}`);
    } catch (err) {
      app.onError(err);
    }
    if (!item) {
      return;
    }
    await app.showModal(MOrderEdit, { order: item.order, nav });
  }

  async onClaimClick(id) {
    const { app } = this.props;
    const api = app.getApi();
    let item;
    try {
      item = await api.get(`/claims/${id}`);
    } catch (err) {
      app.onError(err);
    }
    if (!item) {
      return;
    }
    await app.showModal(MClaimEdit, { claim: item.claim });
  }

  // render helpers

  renderItems(items, type) {
    return items.map((item, index) => this.renderItem(item, type, items.length - index));
  }

  renderItem(item, type, index) {
    const itemId = this.state.itemId || this.props.itemId;
    const rows = this.getComboRows(item, type);
    const title = cn(type === 'order' ? 'Заказ ' : 'Заявка ', `# ${item.id}`);
    const state = item.applied_at ? formatDate(item.applied_at) : type === 'order' ? 'открыт' : 'открыта';
    const flagRecommendations = type === 'order' && item.recommendations.length > 0;
    const higlight = item.id === itemId;
    const indexClassName = cn(higlight && 'ScrollGrid_cellHighlightLeft', 'Grid_cellTextMuted', 'Grid_cellMergeRight');
    return (
      <React.Fragment key={`${type}${item.id}`}>
        <tr className="table-accent-bg" key={`${type}${item.id}`}>
          <td className={indexClassName}>{index}</td>
          <td className="Grid_cellTextLeft Grid_cellMergeRight">
            <VoidLink onClick={() => this.onTitleClick(item.id, type)} text={title} />
          </td>
          <td colSpan="2" className="Grid_cellTextMuted Grid_cellTextLeft Grid_cellMergeRight">
            {state}
          </td>
          <td className="Grid_cellMergeRight">
            {flagRecommendations && (
              <VoidLink onClick={() => this.onOrderClick(item.id, 'recommendations')} text="(Рек.)" />
            )}
          </td>
          <td className="Grid_cellTextRight">
            {item.activities.length} / {item.products.length}
            {type === 'order' && item.own_products.length > 0 && (
              <span className="Grid_cellTextMuted">{` / ${item.own_products.length}`}</span>
            )}
            &nbsp;
          </td>
        </tr>
        {rows.length > 0 && rows.map((x) => this.renderItemRow(x, higlight))}
      </React.Fragment>
    );
  }

  renderItemRow(row, highlight) {
    const workers = row.workers ? ` – ${row.workers}` : '';
    const aiClassName = cn(highlight && 'ScrollGrid_cellHighlightLeft', 'Grid_cellMergeRight');
    const piClassName = cn(row.isOwn && 'Grid_cellTextMuted', 'Grid_cellMergeRight');
    const pClassName = row.isOwn ? 'Grid_cellTextMuted Grid_cellTextRight' : 'Grid_cellTextLeft';
    return (
      <tr key={row.key}>
        <td className={aiClassName}>{row.ai || ''}</td>
        <td colSpan="2" className="Grid_cellTextLeft">
          {row.activity || ''}
          <span className="Grid_cellTextMuted">{workers}</span>
        </td>
        <td className={piClassName}>{row.pi || ''}</td>
        <td colSpan="2" className={pClassName}>
          {row.product || ''}
        </td>
      </tr>
    );
  }

  // other helpers

  getComboRows(item, type) {
    const rows = [];
    const activities = item.activities;
    const products = item.products;
    const ownProducts = item.own_products || [];
    activities.map((x, index) =>
      rows.push({
        key: `${type}${item.id}${index}`,
        ai: index + 1,
        activity: x.activity_name,
        workers: type === 'order' ? this.getWorkersInfo(x) : null,
        pi: null,
        product: null,
        isOwn: null,
      }),
    );
    const n = products.length + ownProducts.length - activities.length;
    if (n > 0) {
      const index = activities.length;
      for (let i = 0; i < n; i++) {
        rows.push({
          key: `${type}${item.id}${index + i}`,
          ai: null,
          activity: null,
          workers: null,
          pi: null,
          product: null,
          isOwn: null,
        });
      }
    }
    products.forEach((x, index) => {
      rows[index].pi = index + 1;
      rows[index].product = x.product_name;
      rows[index].isOwn = false;
    });
    const pos = products.length;
    ownProducts.forEach((x, index) => {
      rows[pos + index].pi = index + 1;
      rows[pos + index].product = x.name;
      rows[pos + index].isOwn = true;
    });
    return rows;
  }

  getWorkersInfo(activity) {
    const { workers } = this.props;
    const list = [];
    for (const worker of activity.workers) {
      const dept = workers.find((x) => x.id === worker.worker_id).dept_short;
      const name = worker.worker_name.split(' ', 1)[0];
      const item = dept ? `${dept}: ${name}` : `${name}`;
      list.push(item);
    }
    return list.join(', ');
  }
}
