import PropTypes from 'prop-types';
import React from 'react';

import { formatDateTime, formatInt, formatMoney, formatOrderStatus, formatPhone } from '../../lib/fmt';
import { formHasError, formNormalizeNumber, formTrimAll, formValidateRegexp } from '../../lib/form';
import { parseMoney, parseNumber } from '../../lib/parser';
import { asyncAlert, coalesce } from '../../lib/utils';
import { getBaseWorkerOptions } from '../../services/worker.service';
import ActivityGrid from '../ActivityGrid';
import Button from '../Button';
import DOrder from '../docs/DOrder';
import FormGroup from '../FormGroup';
import LabelValue from '../LabelValue';
import ModalNav from '../ModalNav';
import OwnProductGrid from '../OwnProductGrid';
import ProductGrid from '../ProductGrid';
import MActivityPicker from './MActivityPicker';
import MContractorView from './MContractorView';
import MHandoverAct from './MHandoverAct';
import MProductPicker from './MProductPicker';
import MVehicleView from './MVehicleView';

const NAV_INFO = 'info';
const NAV_ACTIVITIES = 'activities';
const NAV_PRODUCTS = 'products';
const NAV_OWN_PRODUCTS = 'own_products';
const NAV_ESTIMATION = 'estimation';
const NAV_RECOMMENDATIONS = 'recommendations';

const NAVS = [
  [NAV_INFO, 'Информация'],
  [NAV_ACTIVITIES, 'Работы'],
  [NAV_PRODUCTS, 'З/ч'],
  [NAV_OWN_PRODUCTS, 'З/ч клиента'],
  [NAV_ESTIMATION, 'Проценка'],
  [NAV_RECOMMENDATIONS, 'Рекомендации'],
];

const OPTIONS_ORDER_STATUS = [
  { value: 'new', title: formatOrderStatus('new') },
  { value: 'progress', title: formatOrderStatus('progress') },
  { value: 'ready', title: formatOrderStatus('ready') },
  { value: 'closed', title: formatOrderStatus('closed') },
  { value: 'debt', title: formatOrderStatus('debt') },
  { value: 'cancelled', title: formatOrderStatus('cancelled') },
];

export default class MOrderEdit extends React.Component {
  static propTypes = {
    app: PropTypes.object.isRequired,
    close: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
    // --
    isEditMode: PropTypes.bool,
    nav: PropTypes.object,
  };

  static defaultProps = {
    isEditMode: false,
    nav: NAV_INFO,
  };

  messageHandler;
  reconnectHandler;

  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isEditMode: props.isEditMode,
      isEditModeForEstimation: false,
      nav: props.nav,
      order: props.order,
      contractor: undefined,
      vehicle: undefined,
      workers: [],
      form: this.initForm(props.order),
      orderActivities: [],
      orderProducts: [],
      ownProducts: [],
    };
  }

  render() {
    return (
      <div className="MOrderEdit modal-dialog modal-lg">
        <div className="modal-content">
          {this.renderHeader()}
          {this.renderNav()}
          {this.renderBody()}
          {this.renderFooter()}
        </div>
      </div>
    );
  }

  async componentDidMount() {
    const { app } = this.props;
    const { order } = this.state;
    try {
      const { order: freshOrder } = await app.getApi().get(`/orders/${order.id}`);
      const { contractor } = await app.getApi().get(`/contractors/${order.contractor_id}`);
      const { vehicle } = await app.getApi().get(`/vehicles/${order.vehicle_id}`);
      const { items: workers } = await app.getApi().get('/workers');
      this.setState({
        order: freshOrder,
        contractor,
        vehicle,
        workers,
        orderActivities: this.initOrderActivities(freshOrder),
        orderProducts: this.initOrderProducts(freshOrder),
        ownProducts: this.initOwnProducts(freshOrder),
      });
    } catch (err) {
      app.onError(err);
    }
    this.setupWebSocket();
  }

  componentWillUnmount() {
    const { app } = this.props;
    const ws = app.getWebSocket();
    ws.registerMessageHandler(this.messageHandler);
    ws.registerReconnectHandler(this.reconnectHandler);
  }

  // event handlers

  onNavChange(nav) {
    this.setState({ nav });
  }

  onFormChange(form) {
    this.setState({ form });
  }

  onActivityGridChange(items) {
    this.setState({ orderActivities: items });
  }

  onProductGridChange(items) {
    this.setState({ orderProducts: items });
  }

  onOwnProductGridChange(items) {
    this.setState({ ownProducts: items });
  }

  onEdit() {
    const { app } = this.props;
    const user = app.getUser();
    const { order, nav } = this.state;
    if (nav === NAV_ESTIMATION && user.role === 'logistician' && order.applied_at) {
      this.setState({ isEditModeForEstimation: true });
      return;
    }
    if (order.applied_at) {
      const msg = 'Внимание! Редактирование заказа может изменить остатки на складе. Продолжить?';
      if (!window.confirm(msg)) {
        return;
      }
    }
    this.setState({ isEditMode: true });
  }

  onPrint() {
    const { app } = this.props;
    const { order } = this.state;
    if (!order.activities || !order.products) {
      return;
    }
    app.printDocument(`Заказ # ${order.id}`, DOrder, { order });
  }

  async onHandoverAct() {
    const { app } = this.props;
    const { order } = this.state;
    if (!order.activities || !order.products) {
      return;
    }
    const freshOrder = await app.showModal(MHandoverAct, { order });
    if (freshOrder) {
      this.setState({ order: freshOrder });
    }
  }

  async onApply() {
    const { app, close } = this.props;
    const { order } = this.state;
    const amount = formatMoney(order.product_amount + order.activity_amount, true, true);
    const msg = `Провести заказ # ${order.id} на сумму ${amount}?`;
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().post(`/orders/${order.id}/apply`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      if (err.data && err.data.code === 'out_of_stock') {
        const msg = err.data.products.map((p) => `${p.name} (${p.sku_producer})`).join(', ');
        asyncAlert(`Невозможно провести заказ. На складе не достаточно следующих позиций: ${msg}.`);
      } else {
        app.onError(err);
      }
    }
  }

  async onDelete() {
    const { app, close } = this.props;
    const { order } = this.state;
    const msg = order.applied_at
      ? 'Внимание! Удаление заказа изменит остатки на складе. Удалить заказ?'
      : 'Удалить заказ?';
    if (!window.confirm(msg)) {
      return;
    }
    this.setState({ isLoading: true });
    try {
      await app.getApi().delete(`/orders/${order.id}`);
      close();
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  async onSave() {
    const { app } = this.props;
    const { order, orderActivities, orderProducts, ownProducts } = this.state;
    let form = { ...this.state.form };
    form = formTrimAll(form);
    form = formNormalizeNumber(form, 'mileage');
    form = formValidateRegexp(form, 'mileage', /^\d+$/);
    if (formHasError(form)) {
      this.setState({ nav: NAV_INFO, form });
      asyncAlert('Пожалуйста, исправьте неверно заполненные поля');
      return;
    }
    if (!this.validateProducts()) {
      this.setState({ nav: NAV_PRODUCTS });
      return;
    }
    if (!this.validateOwnProducts()) {
      this.setState({ nav: NAV_OWN_PRODUCTS });
      return;
    }
    this.setState({ isLoading: true });
    try {
      const api = app.getApi();
      const pd = this.getPostData(form, orderActivities, orderProducts, ownProducts);
      const status = form.status;
      await api.put(`/orders/${order.id}`, pd);
      if (status !== order.status) {
        await api.put(`/orders/${order.id}/status`, { status });
      }
      await this.refreshOrder();
      this.setState({
        isLoading: false,
        isEditMode: false,
        isEditModeForEstimation: false,
      });
    } catch (err) {
      this.setState({ isLoading: false });
      app.onError(err);
    }
  }

  onCancel() {
    const { order } = this.state;
    this.setState({
      isEditMode: false,
      isEditModeForEstimation: false,
      form: this.initForm(order),
      orderActivities: this.initOrderActivities(order),
      orderProducts: this.initOrderProducts(order),
      ownProducts: this.initOwnProducts(order),
    });
  }

  async onContractorClick() {
    const { app } = this.props;
    const { contractor } = this.state;
    if (!contractor) {
      return;
    }
    await app.showModal(MContractorView, { contractor });
  }

  async onVehicleClick() {
    const { app } = this.props;
    const { vehicle } = this.state;
    if (!vehicle) {
      return;
    }
    await app.showModal(MVehicleView, { vehicle });
  }

  async onAddActivities() {
    const { app } = this.props;
    const { orderActivities } = this.state;
    const usedIds = orderActivities.map((item) => item.activity_id);
    const selectedItems = await app.showModal(MActivityPicker, { usedIds });
    if (!selectedItems) {
      return;
    }
    const w = this.getWorker();
    const newOrderActivities = selectedItems.map((item) => ({
      activity_id: item.activity.id,
      activity_name: item.activity.name,
      wh: item.wh,
      qty: item.qty,
      workers: w ? [{ worker_id: w.id, worker_name: w.name, wh: item.wh * item.qty }] : [],
    }));
    this.setState({ orderActivities: [...orderActivities, ...newOrderActivities] });
  }

  async onAddProducts() {
    const { app } = this.props;
    const { orderProducts } = this.state;
    const usedIds = orderProducts.map((item) => item.product_id);
    const selectedItems = await app.showModal(MProductPicker, {
      priceType: 2,
      shouldCheckStock: true,
      usedIds,
    });
    if (!selectedItems) {
      return;
    }
    const newOrderProducts = selectedItems.map((item) => ({
      product_id: item.product.id,
      product_name: item.product.name,
      product_sku_producer: item.product.sku_producer,
      product_sku_original: item.product.sku_original,
      product_unit: item.product.unit,
      producer_name: item.product.producer_name,
      producer_country: item.product.producer_country,
      price: formatMoney(item.price, true),
      qty: String(item.qty),
    }));
    this.setState({ orderProducts: [...orderProducts, ...newOrderProducts] });
  }

  async onAddOwnProduct() {
    const { ownProducts } = this.state;
    this.setState({ ownProducts: [...ownProducts, { name: '', qty: '1' }] });
  }

  // render helpers

  renderHeader() {
    const { close } = this.props;
    const { order } = this.state;
    return (
      <div className="modal-header">
        <h5 className="modal-title">Заказ # {order.id}</h5>
        <button type="button" className="close" onClick={() => close()}>
          <span>&times;</span>
        </button>
      </div>
    );
  }

  renderNav() {
    const { nav } = this.state;
    return <ModalNav items={NAVS} nav={nav} onChange={(nav) => this.onNavChange(nav)} />;
  }

  renderBody() {
    return <div className="modal-body overflow-scroll">{this.renderContent()}</div>;
  }

  renderContent() {
    switch (this.state.nav) {
      case NAV_INFO:
        return this.renderInfo();
      case NAV_ACTIVITIES:
        return this.renderActivities();
      case NAV_PRODUCTS:
        return this.renderProducts();
      case NAV_OWN_PRODUCTS:
        return this.renderOwnProducts();
      case NAV_ESTIMATION:
        return this.renderEstimation();
      case NAV_RECOMMENDATIONS:
        return this.renderRecommendations();
      default:
        return null;
    }
  }

  renderFooter() {
    const { isLoading, isEditMode, isEditModeForEstimation, nav, order } = this.state;
    return (
      <div className="modal-footer">
        <div className="mr-auto">
          {!isEditMode && !isEditModeForEstimation && (
            <Button
              className="mr-2"
              type="primary"
              text="Редактировать"
              icon="pen"
              disabled={isLoading || !this.canEdit()}
              onClick={() => this.onEdit()}
            />
          )}
          {!isEditMode && !isEditModeForEstimation && !order.applied_at && (
            <Button
              className="mr-2"
              type="success"
              text="Провести"
              icon="check"
              disabled={isLoading || !this.canApply()}
              onClick={() => this.onApply()}
            />
          )}
          {!isEditMode && !isEditModeForEstimation && (
            <Button
              className="mr-2"
              type="dark"
              text="Печать"
              icon="print"
              disabled={isLoading}
              onClick={() => this.onPrint()}
            />
          )}
          {!isEditMode && !isEditModeForEstimation && (
            <Button
              className="mr-2"
              type="dark"
              text="Акт приёмки-передачи"
              icon="car"
              disabled={isLoading}
              onClick={() => this.onHandoverAct()}
            />
          )}
          {!isEditMode && !isEditModeForEstimation && (
            <Button
              className="mr-2"
              type="danger"
              text="Удалить"
              icon="trash"
              disabled={isLoading || !this.canDelete()}
              onClick={() => this.onDelete()}
            />
          )}
          {isEditMode && nav === NAV_ACTIVITIES && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позиции"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddActivities()}
            />
          )}
          {isEditMode && nav === NAV_PRODUCTS && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позиции"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddProducts()}
            />
          )}
          {isEditMode && nav === NAV_OWN_PRODUCTS && (
            <Button
              className="mr-2"
              type="primary"
              text="Добавить позицию"
              icon="plus"
              disabled={isLoading}
              onClick={() => this.onAddOwnProduct()}
            />
          )}
        </div>
        {(isEditMode || isEditModeForEstimation) && (
          <Button type="secondary" text="Отмена" disabled={isLoading} onClick={() => this.onCancel()} />
        )}
        {(isEditMode || isEditModeForEstimation) && (
          <Button type="success" text="Сохранить" disabled={isLoading} onClick={() => this.onSave()} />
        )}
      </div>
    );
  }

  renderInfo() {
    const { isEditMode, order, form } = this.state;
    const vehicle = `${order.vehicle_brand} ${order.vehicle_model} ${order.vehicle_year}`;
    const phone = formatPhone(order.contractor_details.phone || order.contractor_details.contact_phone);
    return (
      <div>
        <div className="row">
          <LabelValue
            className="col"
            label="Клиент"
            value={order.contractor_name}
            onValueClick={() => this.onContractorClick()}
          />
          <LabelValue className="col" label="Телефон" value={phone} />
          <LabelValue className="col" label="Приём заказа" value={formatDateTime(order.created_at)} />
        </div>
        <div className="row">
          <LabelValue className="col" label="Автомобиль" value={vehicle} onValueClick={() => this.onVehicleClick()} />
          <LabelValue className="col" label="Гос. номер" value={order.vehicle_reg} />
          <LabelValue className="col" label="Начало работ" value={formatDateTime(order.started_at)} />
        </div>
        <div className="row">
          <LabelValue className="col" label="Стоимость н/ч" value={formatMoney(order.wh_price, true, true)} />
          <LabelValue className="col" label="Работа" value={formatMoney(order.activity_amount, true, true)} />
          <LabelValue className="col" label="Завершение работ" value={formatDateTime(order.finished_at)} />
        </div>
        <div className="row">
          <LabelValue className="col" label="З/ч" value={formatMoney(order.product_amount, true, true)} />
          <LabelValue
            className="col"
            label="Сумма"
            value={formatMoney(order.activity_amount + order.product_amount, true, true)}
          />
          <LabelValue className="col" label="Закрытие заказа" value={formatDateTime(order.closed_at)} />
        </div>
        <div className="row">
          <FormGroup
            className="col"
            type="select"
            options={this.getStatusOptions()}
            name="status"
            label="Статус"
            form={form}
            disabled={true}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="select"
            options={this.getWorkerOptions()}
            name="worker"
            label="Исполнитель"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
          <FormGroup
            className="col"
            type="text"
            name="mileage"
            label="Пробег"
            form={form}
            disabled={!isEditMode}
            onChange={(newForm) => this.onFormChange(newForm)}
          />
        </div>
      </div>
    );
  }

  renderActivities() {
    const { app } = this.props;
    const { isEditMode, order, orderActivities } = this.state;
    return (
      <ActivityGrid
        app={app}
        type="order"
        items={orderActivities}
        whPrice={order.wh_price}
        isEditMode={isEditMode}
        onChange={(items) => this.onActivityGridChange(items)}
      />
    );
  }

  renderProducts() {
    const { isEditMode, orderProducts } = this.state;
    return (
      <ProductGrid
        items={orderProducts}
        type="order"
        isEditMode={isEditMode}
        onChange={(items) => this.onProductGridChange(items)}
      />
    );
  }

  renderOwnProducts() {
    const { isEditMode, ownProducts, order } = this.state;
    return (
      <OwnProductGrid
        items={ownProducts}
        checked={order.own_products_is_checked}
        isEditMode={isEditMode}
        onChange={(items) => this.onOwnProductGridChange(items)}
      />
    );
  }

  renderEstimation() {
    const { isEditMode, isEditModeForEstimation, form } = this.state;
    return (
      <FormGroup
        className="col"
        type="textarea"
        rows={12}
        name="estimation"
        label="Проценка (не выводится на печатную форму)"
        form={form}
        disabled={!isEditMode && !isEditModeForEstimation}
        onChange={(newForm) => this.onFormChange(newForm)}
      />
    );
  }

  renderRecommendations() {
    const { isEditMode, form } = this.state;
    return (
      <FormGroup
        className="col"
        type="textarea"
        rows={12}
        name="recommendations"
        label="Рекомендации (выводятся на печатную форму)"
        form={form}
        disabled={!isEditMode}
        onChange={(newForm) => this.onFormChange(newForm)}
      />
    );
  }

  // other helpers

  canEdit() {
    const { nav } = this.state;
    const { app, order } = this.props;
    const user = app.getUser();
    return app.hasRole('boss') || !order.applied_at || (nav === NAV_ESTIMATION && user.role === 'logistician');
  }

  canDelete() {
    const { app } = this.props;
    return app.hasRole('boss');
  }

  canApply() {
    const { app } = this.props;
    return app.hasRole('boss', 'logistician');
  }

  setupWebSocket() {
    const { app } = this.props;
    const { order } = this.state;
    const ws = app.getWebSocket();
    this.messageHandler = ws.getMessageHandler();
    this.reconnectHandler = ws.getReconnectHandler();
    ws.registerMessageHandler(async (msg) => {
      if (msg.event === 'order_update') {
        const { isEditMode } = this.state;
        const { ord_id } = msg.details;
        if (ord_id === order.id && !isEditMode) {
          await this.refreshOrder();
        }
      }
    });
    ws.registerReconnectHandler(async () => {
      const { isEditMode } = this.state;
      if (!isEditMode) {
        await this.refreshOrder();
      }
    });
  }

  initForm(order) {
    return {
      status: order.status,
      worker: order.worker_id ? String(order.worker_id) : '',
      mileage: formatInt(order.mileage, true),
      estimation: order.estimation,
      recommendations: order.recommendations,
    };
  }

  initOrderActivities(order) {
    return [...order.activities];
  }

  initOrderProducts(order) {
    return order.products.map((item) => ({
      ...item,
      qty: String(item.qty),
      price: formatMoney(item.price, true),
    }));
  }

  initOwnProducts(order) {
    return order.own_products.map((item) => ({
      ...item,
      qty: String(item.qty),
    }));
  }

  getPostData(form, orderActivities, orderProducts, ownProducts) {
    return {
      worker_id: form.worker ? Number(form.worker) : null,
      mileage: coalesce(parseNumber(form.mileage), null),
      estimation: form.estimation,
      recommendations: form.recommendations,
      activities: orderActivities.map((item) => ({
        activity_id: item.activity_id,
        wh: item.wh,
        qty: item.qty,
        workers: item.workers.map((w) => ({
          worker_id: w.worker_id,
          wh: w.wh,
        })),
      })),
      products: orderProducts.map((item) => ({
        product_id: item.product_id,
        qty: parseNumber(item.qty),
        price: parseMoney(item.price),
      })),
      own_products: ownProducts.map((item) => ({
        name: item.name,
        qty: parseNumber(item.qty),
      })),
    };
  }

  getStatusOptions() {
    return OPTIONS_ORDER_STATUS.map((option) => ({
      ...option,
      disabled: !this.isStatusAvailable(option.value),
    }));
  }

  isStatusAvailable(status) {
    const { order } = this.state;
    if (order.status === status) {
      return true;
    }
    switch (`${order.status} -> ${status}`) {
      case 'new -> progress':
      case 'new -> cancelled':
      case 'progress -> new':
      case 'progress -> ready':
      case 'ready -> progress':
      case 'ready -> closed':
      case 'ready -> debt':
      case 'debt -> ready':
      case 'debt -> closed':
      case 'closed -> ready':
      case 'cancelled -> new':
        return true;
      default:
        return false;
    }
  }

  getWorkerOptions() {
    const { order, workers } = this.state;
    const first = { value: '', title: 'Не выбран' };
    const options = getBaseWorkerOptions(workers);
    if (options.length === 0 && order.worker_id) {
      options.push({
        value: order.worker_id,
        title: order.worker_name,
      });
    }
    return [first, ...options];
  }

  getWorker() {
    const { workers, form } = this.state;
    if (!form.worker) {
      return undefined;
    }
    const workerId = Number(form.worker);
    return workers.find((w) => w.id === workerId);
  }

  validateProducts() {
    const { orderProducts } = this.state;
    for (const item of orderProducts) {
      const price = parseMoney(item.price);
      const qty = parseNumber(item.qty);
      if (price === undefined || price < 0) {
        asyncAlert('Неверно введено цена');
        return false;
      }
      if (qty === undefined || qty <= 0) {
        asyncAlert('Неверно введено количество');
        return false;
      }
    }
    return true;
  }

  validateOwnProducts() {
    const { ownProducts } = this.state;
    for (const item of ownProducts) {
      const qty = parseNumber(item.qty);
      if (!item.name) {
        asyncAlert('Не указано наименование');
        return false;
      }
      if (qty === undefined || qty <= 0) {
        asyncAlert('Неверно введено количество');
        return false;
      }
    }
    return true;
  }

  async refreshOrder() {
    const { app } = this.props;
    const { order } = this.state;
    const { order: freshOrder } = await app.getApi().get(`/orders/${order.id}`);
    this.setState({
      order: freshOrder,
      orderActivities: this.initOrderActivities(freshOrder),
      orderProducts: this.initOrderProducts(freshOrder),
      ownProducts: this.initOwnProducts(freshOrder),
      form: this.initForm(freshOrder),
    });
  }
}
