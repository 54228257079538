import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import React from 'react';

import { formatDate, formatMoney, formatWh } from '../../lib/fmt';
import { cn } from '../../lib/utils';

export default class DWorkerReport extends React.Component {
  static propTypes = {
    report: PropTypes.object.isRequired,
    date1: PropTypes.string.isRequired,
    date2: PropTypes.string.isRequired,
    isGroupByOrder: PropTypes.bool.isRequired,
    // --
    workerId: PropTypes.number,
  };

  render() {
    return (
      <div className="DWorkerReport">
        {this.renderHeader()}
        {this.renderWorkers()}
      </div>
    );
  }

  // render helpers

  renderHeader() {
    const { date1, date2 } = this.props;
    const d1 = dateFns.format(date1, 'DD.MM.YYYY');
    const d2 = dateFns.format(date2, 'DD.MM.YYYY');
    const period = d1 === d2 ? d1 : `${d1} – ${d2}`;
    return (
      <div>
        <h1 className="text-center">Отчёт по исполнителям</h1>
        <h2 className="text-center mb-3">({period})</h2>
      </div>
    );
  }

  renderWorkers() {
    const { workerId, isGroupByOrder } = this.props;
    const { workers, wh, amount } = this.processReport();
    const trSummaryClassName = cn('font-weight-bold', workerId && 'd-none');
    return (
      <table className="table table-bordered text-center">
        <thead>
          <tr>
            <th>Номер</th>
            <th>Дата</th>
            <th>Заказ</th>
            <th>Автомобиль</th>
            {!isGroupByOrder && <th>Вид работы</th>}
            <th>Н/ч</th>
            <th>Цена н/ч</th>
            <th>Стоимость</th>
          </tr>
        </thead>
        <tbody>
          {workers.map((w) => this.renderWorker(w))}
          <tr className={trSummaryClassName}>
            <td colSpan={isGroupByOrder ? 4 : 5}>Итого:</td>
            <td>{formatWh(wh)}</td>
            <td></td>
            <td>{formatMoney(amount, true, true)}</td>
          </tr>
        </tbody>
      </table>
    );
  }

  renderWorker(w) {
    const { workerId, isGroupByOrder } = this.props;
    if (workerId && workerId !== w.id) {
      return null;
    }
    return (
      <React.Fragment key={w.id}>
        <tr className="font-weight-bold">
          <td colSpan={isGroupByOrder ? 7 : 8} className="table-active">
            {w.name || 'Без исполнителя'}
          </td>
        </tr>
        {(isGroupByOrder ? w.orders : w.items).map((item, index) => this.renderItem(item, index))}
        <tr className="font-weight-bold">
          <td colSpan={isGroupByOrder ? 4 : 5}>Итого по исполнителю:</td>
          <td>{formatWh(w.wh)}</td>
          <td></td>
          <td>{formatMoney(w.amount, true, true)}</td>
        </tr>
      </React.Fragment>
    );
  }

  renderItem(item, index) {
    const { isGroupByOrder } = this.props;
    return (
      <tr key={`${item.worker_id}-${index}`}>
        <td>{index + 1}</td>
        <td>{formatDate(item.ord_applied_at)}</td>
        <td>{item.ord_id}</td>
        <td>
          {item.vehicle_brand} {item.vehicle_model} - {item.vehicle_reg}
        </td>
        {!isGroupByOrder && <td>{item.activity_name}</td>}
        <td>{formatWh(item.wh)}</td>
        <td>{formatMoney(item.ord_wh_price, true, true)}</td>
        <td>{formatMoney(item.amount, true, true)}</td>
      </tr>
    );
  }

  // other helpers

  processReport() {
    const { report, isGroupByOrder } = this.props;
    const { items } = report;
    const { workers, wh, amount } = this.groupItemsByWorker(items);
    if (isGroupByOrder) {
      this.groupItemsInWorkersByOrder(workers);
    }
    return { workers, wh, amount };
  }

  groupItemsByWorker(items) {
    let wh = 0;
    let amount = 0;
    const workersMap = new Map();
    items.forEach((item) => {
      if (workersMap.has(item.worker_id)) {
        const temp = workersMap.get(item.worker_id);
        workersMap.set(item.worker_id, {
          ...temp,
          wh: temp.wh + item.wh,
          amount: temp.amount + item.amount,
          items: [...temp.items, item],
        });
      } else {
        workersMap.set(item.worker_id, {
          id: item.worker_id,
          name: item.worker_name,
          wh: item.wh,
          amount: item.amount,
          items: [item],
        });
      }
      wh += item.wh;
      amount += item.amount;
    });
    const workers = Array.from(workersMap.values());
    return { workers, wh, amount };
  }

  groupItemsInWorkersByOrder(workers) {
    workers.forEach((worker) => {
      const orders = new Map();
      worker.items.forEach((item) => {
        if (orders.has(item.ord_id)) {
          const temp = orders.get(item.ord_id);
          orders.set(item.ord_id, {
            ...temp,
            wh: temp.wh + item.wh,
            amount: temp.amount + item.amount,
          });
        } else {
          orders.set(item.ord_id, item);
        }
      });
      worker.orders = Array.from(orders.values());
    });
  }
}
